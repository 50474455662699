var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('French Section')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.name","rules":"required","name":"The Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Name'),"name":"name","fou":""},on:{"keyup":_vm.generateSomeFields},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.url","rules":"required","name":"The URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('URL'),"name":"url","fou":""},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.description","rules":"","name":"The Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Description'),"name":"description","fou":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.cost","rules":"required","name":"The Cost"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Cost'),"name":"cost","fou":""},model:{value:(_vm.formData.cost),callback:function ($$v) {_vm.$set(_vm.formData, "cost", $$v)},expression:"formData.cost"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.category_id","rules":"required","name":"The Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"category_id","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Category'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Category'),"list":_vm.builderData.categoryList,"listItemLabel":'name',"listItemValue":'id'},on:{"change":_vm.changeCategory},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.training_sessions","rules":"required","name":"The Training Sessions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"training_sessions","size":"large","filterable":"","clearable":"","multiple":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Training Sessions'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Training Sessions'),"list":_vm.builderData.trainingSessionList,"listItemLabel":'title',"listItemValue":'id'},model:{value:(_vm.formData.training_sessions),callback:function ($$v) {_vm.$set(_vm.formData, "training_sessions", $$v)},expression:"formData.training_sessions"}})]}}])})],1)]),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Pay In Installments')))]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.pay_by_installments),callback:function ($$v) {_vm.$set(_vm.formData, "pay_by_installments", $$v)},expression:"formData.pay_by_installments"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1),(_vm.formData.pay_by_installments)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"fr.first_installment_value","rules":"required","name":"The First installment Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('First installment value'),"name":"cost","fou":""},model:{value:(_vm.formData.first_installment_value),callback:function ($$v) {_vm.$set(_vm.formData, "first_installment_value", $$v)},expression:"formData.first_installment_value"}})]}}],null,false,3901070103)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"fr.second_installment_value","rules":"required","name":"The Second installment Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Second installment value (a month before the session starts)'),"name":"cost","fou":""},model:{value:(_vm.formData.second_installment_value),callback:function ($$v) {_vm.$set(_vm.formData, "second_installment_value", $$v)},expression:"formData.second_installment_value"}})]}}],null,false,766503914)})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"vid":"fr.installment_amount","rules":"required","name":"The Installment Amount Per Month Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Monthly payment'),"name":"cost","fou":""},model:{value:(_vm.formData.installment_amount),callback:function ($$v) {_vm.$set(_vm.formData, "installment_amount", $$v)},expression:"formData.installment_amount"}})]}}],null,false,1719702344)})],1),(_vm.checkInstallmentsLabel)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('First Installment'))+" : "),_c('span',{staticStyle:{"color":"#EC6459"}},[_vm._v(_vm._s(_vm.checkInstallmentsLabel.first_installment))])]),_c('div',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Last Installment'))+" : "),_c('span',{staticStyle:{"color":"#EC6459"}},[_vm._v(_vm._s(_vm.checkInstallmentsLabel.last_installment))])])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Is Active')))]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Hide The Cost')))]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.hide_cost),callback:function ($$v) {_vm.$set(_vm.formData, "hide_cost", $$v)},expression:"formData.hide_cost"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }